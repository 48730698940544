import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 上报
const supReport= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/supervise/report', params)
}

// 监督类型
const supTypes= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/supervise/types', params)
}
// 列表
const supRecords = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/supervise/records', params)
}


export default {
	supReport,
	supTypes,
	supRecords
}
